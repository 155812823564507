<template>
  <div class="page-layout-non-grid-wrapper">
    <AppHeader />
    <NuxtPage class="page-layout-non-grid" />
  </div>
</template>

<style lang="scss" scoped>
.page-layout-non-grid {
  flex: 1;
  position: relative;
  padding-top: calc(var(--logo-height) + 2 * var(--topbar-padding-vertical));
  
  &-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}
</style>
